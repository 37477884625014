@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply h-screen;
  @apply bg-gradient-to-b;
  @apply from-0% from-[#1a1930];
  @apply to-100% to-[#555555];
}
